<template>
    <section class="zonas-comunes-historial-listado">
        <cargando v-if="cargando" />
        <div class="row d-flex mx-3 justify-content-between">
            <span class="f-20 f-500 text-black">Historial de reservas</span>
            <div class="col-3">
                <el-input v-model="search" placeholder="Buscar por nombre del solicitante" prefix-icon="icon-search"
                          size="small"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12 f-17">
                <el-table :data="reservas" height="calc(100vh - 215px)" style="width: 100%" @row-click="verReserva">
                    <el-table-column sortable prop="zona.nombre" min-width="70" label="Zona">
                        <template slot-scope="scope">
                            <div class="d-flex tres-puntos">
                                <p class="f-16 ml-2 my-auto tres-puntos"> {{ scope.row.zona.nombre }}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="nombre" min-width="100" label="Solicitante">
                        <template slot-scope="scope">
                            <div class="d-flex tres-puntos">
                                <div class="rounded-circle d-middle-center bg-azulclaro">
                                    <i class="icon-account-outline f-20" />
                                </div>
                                <p class="f-16 ml-2 my-auto tres-puntos">
                                    {{ scope.row.usuario.nombre }} {{
                                        scope.row.usuario.apellido }}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vivienda_grupo" min-width="70" label="Grupo de viviendas">
                        <template slot-scope="scope">
                            <div class="d-flex tres-puntos">
                                <p class="f-16 ml-2 my-auto tres-puntos"> {{ scope.row.usuario.vivienda_grupo }} </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vivienda" min-width="70" label="Vivienda">
                        <template slot-scope="scope">
                            <div class="d-flex tres-puntos">
                                <p class="f-16 ml-2 my-auto tres-puntos"> {{ scope.row.usuario.vivienda_nombre }} </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="fecha" min-width="60" label="Fecha programado">
                        <template slot-scope="scope">
                            <p>{{ scope.row.fecha | helper-fecha('DD MMM YYYY') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="hora_inicio" min-width="50" label="Horario">
                        <template slot-scope="scope">
                            <p> {{ formatearHora(scope.row.hora_inicio) }} - {{ formatearHora(scope.row.hora_fin) }} </p>
                        </template>
                    </el-table-column>

                    <el-table-column prop="estado" width="130">
                        <template slot-scope="scope">
                            <div class="">
                                <span v-show="[31, 32, 33].includes(scope.row.estado) && currentTime < scope.row.fecha"
                                      class="rounded-pill px-3 py-1 text-center f-12 bg-verde-claro"
                                >
                                    Reservada
                                </span>
                                <span v-show="[31, 32, 33].includes(scope.row.estado) && currentTime > scope.row.fecha"
                                      class="rounded-pill px-3 py-1 text-center f-12 bg-finalizado"
                                >
                                    Finalizada
                                </span>
                                <span v-show="[21, 23, 24].includes(scope.row.estado)"
                                      class="rounded-pill px-3 py-1 text-center f-12 bg-cancelado"
                                >
                                    Cancelada
                                </span>
                                <span v-show="[22].includes(scope.row.estado)"
                                      class="rounded-pill px-3 py-1 text-center f-12 bg-cancelado"
                                >
                                    Rechazada
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <div slot="empty" class="row">
                        <div class="col-12 text-center">
                            <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">
                                No hemos encontrado coincidencias con tu
                                búsqueda
                            </p>
                        </div>
                    </div>
                    <infinite-loading slot="append" force-use-infinite-wrapper=".el-table__body-wrapper"
                                      @infinite="listar"
                    />
                </el-table>
            </div>
        </div>
    </section>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading';
import Reservas from '~/services/reservas'
export default {
    components: {
        InfiniteLoading
    },
    data(){
        return {
            loading: false,
            page: 1,
            reservas: [],
            search: '',
            cargando: false
        }
    },
    computed: {
        currentTime(){
            return moment().format('YYYY-MM-DD hh:mm:ss').toString()
        }
    },
    watch: {
        search(){
            this.buscador()
        }
    },
    methods: {
        verReserva(row){
            this.$router.push({ name: 'zonas.reservas.ver', params: { id_reserva: row.id } })
        },
        async listar($state){
            try {
                const state = $state
                this.cargando = true
                let params = {
                    page: this.page,
                    search: this.search,
                    estados: "21,22,23,24,31,32,33",
                }
                const { data } = await Reservas.listar(params)
                if (data.data.data.length){
                    this.page += 1;
                    this.reservas.push(...data.data.data)
                    if (typeof state !== 'undefined'){
                        state.loaded();
                    }

                } else {
                    if (typeof state !== 'undefined'){
                        state.complete();
                    }
                }

            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },
        async buscador(){
            const busqueda = async() => {
                this.reservas = []
                this.page = 1
                this.listar()
            }
            await this.delay(busqueda)
        },
    }
}
</script>
<style lang="scss" scoped></style>